import { Options } from "vue-class-component";
import { useToast } from "vue-toastification";
const toast = useToast();
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import BalanceUpTableItem from "@/entity/attendance/duty/BalanceUpTableItem";
import API from "@/api/attendance/duty/balanceUp";
import BatchAPI from "@/api/attendance/duty/batch";

@Options({
    name: "app-attendance-duty-balanceUp",
    components: {},
})
export default class BalanceUp extends BaseTableMixins<BalanceUpTableItem> implements Partial<BaseTableInterface<BalanceUpTableItem>> {
    private promptInfo: any = {};
    protected batchState: any = {};

    public created(): void {
        this.setInterface(this);
        this.editTableItem = new BalanceUpTableItem();
        this.editModalItem = new BalanceUpTableItem();
    }

    public activated(): void {
        this.getList(1);
        this.getBatch();
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //获取批次状态
    protected getBatch() {
        BatchAPI.getBatchOne({})
            .then((res) => {
                this.batchState = res.data.records.batchState;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    //上传余额数据
    public btnUploadBalance() {
        this.isLoading = true;
        API.uploadBalance({})
            .then((res) => {
                this.promptInfo = res.data.prompt;
                if (this.$tools.isEmpty(this.promptInfo)) {
                    toast.success("上传成功");
                    this.isLoading = false;
                } else {
                    alert("上传失败内容：" + this.promptInfo + " 请回到假期数据页面修改内容后再次上传提交");
                    toast.warning("部分信息上传成功");
                    this.isLoading = false;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
}
